import React, { useRef } from "react";
import { Input, FormControl } from "@chakra-ui/react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';
import { enUS, fr } from 'date-fns/locale';
import "./CustomDatePicker.css";

const CustomDatePicker = ({ selectedDate, onChange }) => {
  const { t, i18n } = useTranslation();
  const inputRef = useRef(null);
  const inputBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const locale = i18n.language === 'fr' ? fr : enUS;

  const CustomInput = React.forwardRef(({ value, onClick, placeholder, ...props }, ref) => (
    <Input
      ref={ref}
      value={value || ''}
      onClick={(e) => {
        e.preventDefault();
        onClick?.();
      }}
      bg={inputBg}
      borderColor={borderColor}
      _hover={{ borderColor: "gray.400" }}
      _focus={{ borderColor: "blue.500", boxShadow: "outline" }}
      placeholder={placeholder}
      readOnly={true}
      inputMode="none"
      onFocus={(e) => e.target.blur()} // Immediately blur on any focus attempt
      style={{ 
        caretColor: 'transparent', // Hide the cursor
        cursor: 'pointer', // Show pointer cursor instead of text cursor
        WebkitTapHighlightColor: 'transparent', // Remove tap highlight on mobile
      }}
      {...props}
    />
  ));

  return (
    <FormControl position="relative">
      <ReactDatePicker
        selected={selectedDate}
        onChange={onChange}
        dateFormat={i18n.language === 'fr' ? "dd/MM/yyyy" : "yyyy-MM-dd"}
        locale={locale}
        ref={inputRef}
        customInput={
          <CustomInput
            placeholder={t("select_date")}
          />
        }
        popperClassName="react-datepicker-popper-custom"
        disabledKeyboardNavigation={true} // Disable keyboard navigation
        showPopperArrow={false}
      />
    </FormControl>
  );
};

export default CustomDatePicker;