// components/sidebar/components/Content.js

import { Box, Flex, Stack } from "@chakra-ui/react";
// Custom Components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import React from "react";
import PropTypes from "prop-types";

function SidebarContent({ routes, onClose }) {
  return (
    <Flex direction="column" height="100%" pt="25px" px="16px" borderRadius="30px">
      <Brand />
      <Stack direction="column" mb="auto" mt="8px">
        <Box ps="0px" pe={{ md: "16px", "2xl": "1px" }}>
          {/* Pass onClose to Links */}
          <Links routes={routes} onClose={onClose} />
        </Box>
      </Stack>

      <Box mt="60px" mb="40px" borderRadius="30px">
        <SidebarCard />
      </Box>
    </Flex>
  );
}

SidebarContent.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SidebarContent;
