// src/views/admin/default/components/WorkingHours.jsx

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { useAuth } from "../../auth/AuthContext.jsx";
import { db } from "firebaseConfig.js"; // Adjust the path as necessary
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { useTranslation } from 'react-i18next'; // Import useTranslation

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

// Function to generate default working hours
const getDefaultWorkingHours = () => {
  const defaultHours = {};
  daysOfWeek.forEach((day) => {
    defaultHours[day] = [{ start: "08:20", end: "20:00" }];
  });
  return defaultHours;
};

export default function WorkingHours() {
  const { t } = useTranslation(); // Initialize the translation hook
  const { currentUser } = useAuth();
  const toast = useToast();
  const [workingHours, setWorkingHours] = useState(getDefaultWorkingHours());

  useEffect(() => {
    const fetchWorkingHours = async () => {
      if (!currentUser) return;

      const docRef = doc(db, "working_hours", currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setWorkingHours(docSnap.data());
      } else {
        setWorkingHours(getDefaultWorkingHours()); // Set defaults if no data
      }
    };

    fetchWorkingHours();
  }, [currentUser]);

  const handleAddInterval = (day, index) => {
    setWorkingHours((prev) => {
      const newIntervals = [...prev[day]];
      // If index is -1, add at the start
      if (index === -1) {
        newIntervals.unshift({ start: "08:20", end: "20:00" });
      } else {
        // Insert a new interval after the current one
        newIntervals.splice(index + 1, 0, { start: "08:20", end: "20:00" });
      }
      return {
        ...prev,
        [day]: newIntervals,
      };
    });
  };

  const handleRemoveInterval = (day, index) => {
    setWorkingHours((prev) => {
      const updatedIntervals = prev[day].filter((_, i) => i !== index);
      return {
        ...prev,
        [day]: updatedIntervals,
      };
    });
  };

  const handleTimeChange = (day, index, field, value) => {
    setWorkingHours((prev) => ({
      ...prev,
      [day]: prev[day].map((interval, i) =>
        i === index ? { ...interval, [field]: value } : interval
      ),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      toast({
        title: t("not_authenticated_title"),
        description: t("not_authenticated_description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Validation: Ensure end time is after start time
    for (const day of daysOfWeek) {
      for (const interval of workingHours[day]) {
        if (interval.end <= interval.start) {
          toast({
            title: t("invalid_time_interval_title"),
            description: t("invalid_time_interval_description", { day }),
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }
    }

    try {
      const docRef = doc(db, "working_hours", currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Update existing document
        await updateDoc(docRef, workingHours);
        toast({
          title: t("working_hours_updated_title"),
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Create new document
        await setDoc(docRef, workingHours);
        toast({
          title: t("working_hours_set_title"),
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating working hours:", error);
      toast({
        title: t("error_title"),
        description: t("error_description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const cardBg = useColorModeValue("white", "gray.700");
  const cardBorder = useColorModeValue("gray.200", "gray.600");
  const labelFontSize = "sm"; // Smaller font size for labels
  const inputFontSize = "sm"; // Smaller font size for inputs
  const buttonSize = "xs"; // Smaller button size

  return (
    <Box
      bg={cardBg}
      borderWidth="1px"
      borderColor={cardBorder}
      borderRadius="lg"
      p={6}
      w="100%"
    >
      <Text fontSize="xl" mb={4} fontWeight="bold" textAlign="center">
        {t("set_working_hours")}
      </Text>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4}>
          {daysOfWeek.map((day) => (
            <Box key={day}>
              <FormLabel fontWeight="semibold" fontSize={labelFontSize}>
                {t(day)}
              </FormLabel>
              {workingHours[day].length > 0 ? (
                <Grid templateColumns="3fr 3fr auto auto" gap={2} alignItems="center">
                  {workingHours[day].map((interval, index) => (
                    <React.Fragment key={index}>
                      <Input
                        type="time"
                        value={interval.start}
                        onChange={(e) =>
                          handleTimeChange(day, index, "start", e.target.value)
                        }
                        required
                        fontSize={inputFontSize}
                        minWidth="80px"
                      />
                      <Input
                        type="time"
                        value={interval.end}
                        onChange={(e) =>
                          handleTimeChange(day, index, "end", e.target.value)
                        }
                        required
                        fontSize={inputFontSize}
                        minWidth="80px"
                      />
                      <Tooltip label={t("delete_interval")} aria-label="Delete Interval">
                        <IconButton
                          aria-label={t("delete_interval")}
                          icon={<DeleteIcon />}
                          colorScheme="red"
                          onClick={() => handleRemoveInterval(day, index)}
                          size={buttonSize}
                          variant="ghost"
                          p={1} // Reduced padding
                        />
                      </Tooltip>
                      <Tooltip label={t("add_interval")} aria-label="Add Interval">
                        <IconButton
                          aria-label={t("add_interval")}
                          icon={<AddIcon />}
                          onClick={() => handleAddInterval(day, index)}
                          colorScheme="teal"
                          variant="ghost"
                          size={buttonSize}
                          p={1} // Reduced padding
                        />
                      </Tooltip>
                    </React.Fragment>
                  ))}
                </Grid>
              ) : (
                <Tooltip label={t("add_interval")} aria-label="Add Interval">
                  <IconButton
                    aria-label={t("add_interval")}
                    icon={<AddIcon />}
                    onClick={() => handleAddInterval(day, -1)} // -1 to add at the start
                    colorScheme="teal"
                    variant="ghost"
                    size={buttonSize}
                    p={1} // Reduced padding
                  />
                </Tooltip>
              )}
            </Box>
          ))}
          <Button type="submit" colorScheme="teal" size='sm'>
            {t("save")}
          </Button>
        </Stack>
      </form>
    </Box>
  );
}
