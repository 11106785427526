// components/sidebar/components/Links.js

/* eslint-disable */
import React, { useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
// Chakra UI Imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next"; // Import useTranslation

export function SidebarLinks({ routes, onClose }) {
  const { t } = useTranslation(); // Initialize the translation hook

  // Chakra UI Color Modes
  const location = useLocation();
  const activeColor = useColorModeValue("gray.700", "white");
  const inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");
  const activeIcon = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  // Ref to store the timeout ID
  const timeoutRef = useRef(null);

  // Cleanup timeout on unmount to prevent memory leaks
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Function to check if the route is active
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  // Recursive function to create links, handling categories if present
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.category) {
        // If the route has a category, render the category heading and its items
        return (
          <React.Fragment key={index}>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight="bold"
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt="18px"
              pb="12px"
            >
              {route.name} {/* Assuming category names are not translation keys */}
            </Text>
            {createLinks(route.items)}
          </React.Fragment>
        );
      } else if (route.layout === "/admin" || route.layout === "/auth") {
        // Render the NavLink only if the route is meant for admin or auth layouts
        return (
          <NavLink
            key={index}
            to={route.layout + route.path}
            onClick={() => {
              // Set a timeout to call onClose after 700 milliseconds
              timeoutRef.current = setTimeout(onClose, 700);
            }}
            style={{ textDecoration: "none" }} // Optional: Remove default link styling
          >
            {route.icon ? (
              <Box>
                <HStack
                  spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
                  py="5px"
                  ps="10px"
                >
                  <Flex w="100%" alignItems="center" justifyContent="center">
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase()) ? activeIcon : textColor
                      }
                      me="18px"
                    >
                      {route.icon}
                    </Box>
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase()) ? activeColor : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                      }
                    >
                      {t(route.name)} {/* Translate the route name */}
                    </Text>
                  </Flex>
                  <Box
                    h="36px"
                    w="4px"
                    bg={
                      activeRoute(route.path.toLowerCase()) ? brandColor : "transparent"
                    }
                    borderRadius="5px"
                  />
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
                  py="5px"
                  ps="10px"
                >
                  <Text
                    me="auto"
                    color={
                      activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }
                  >
                    {t(route.name)} {/* Translate the route name */}
                  </Text>
                  <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
      return null; // If the route doesn't match any condition, render nothing
    });
  };

  // Render the sidebar links
  return createLinks(routes);
}

SidebarLinks.propTypes = {
  routes: PropTypes.array.isRequired, // Ensure routes prop is passed and is an array
  onClose: PropTypes.func.isRequired, // Ensure onClose is passed and is a function
};

export default SidebarLinks;
