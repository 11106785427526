// client/src/views/admin/profile/Banner.jsx

import {
  Image,
  Box,
  Text,
  useColorModeValue,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";

// React imports
import React, { useState, useEffect } from "react";

// Authentication context
import { useAuth } from "../../auth/AuthContext.jsx";

// Import Firebase services
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { storage, db } from "firebaseConfig.js"; // Adjust the path as necessary
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";

// Translation hook
import { useTranslation } from 'react-i18next';
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

export default function Banner(props) {
  const { t } = useTranslation(); // Initialize the translation hook
  const { currentUser, userData, refreshUserData } = useAuth();
  const profile = userData?.profile;
  const name = userData?.name;

  // Path to default banner image in public folder
  const defaultBanner = `${process.env.PUBLIC_URL}/assets/img/profile/default_banner.png`;

  // Path to default profile image in public folder
  const defaultProfileImage = `${process.env.PUBLIC_URL}/assets/img/profile/default_profile.png`;

  const { banner } = props;

  // State for banner fallback
  const [bannerSrc, setBannerSrc] = useState(banner || defaultBanner);

  // Chakra UI color modes
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  // State for handling profile picture upload
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [imgSrc, setImgSrc] = useState(defaultProfileImage);
  const [hasFallback, setHasFallback] = useState(false);
  const toast = useToast();

  // Fetch profile image
  useEffect(() => {
    const fetchProfileImage = async () => {
      if (profile && currentUser) {
        const profileRef = ref(storage, `profiles/${profile}`);
        try {
          const url = await getDownloadURL(profileRef);
          setImgSrc(url);
        } catch (error) {
          setImgSrc(defaultProfileImage);
        }
      }
    };

    fetchProfileImage();
  }, [profile, currentUser]);

  // Handle profile picture upload
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleSubmitProfilePicture = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast({
        title: t("no_file_selected_title"),
        description: t("no_file_selected_description"),
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setUploading(true);
    try {
      const userId = currentUser.uid;
      const file = selectedFile;
      const timestamp = Date.now();
      const fileName = `${userId}-${timestamp}-${file.name}`;
      const storageRef = ref(storage, `profiles/${fileName}`);

      if (profile != "default_profile.png") {
        const previousFileRef = ref(storage, `profiles/${profile}`);
        try {
          await deleteObject(previousFileRef);
        } catch (deleteError) {
          console.error("Error deleting previous profile picture:", deleteError);
        }
      }

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error("Upload failed:", error);
          setUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const userDocRef = doc(db, "users", userId);
          await updateDoc(userDocRef, { profile: fileName });
          setImgSrc(downloadURL);
          setSelectedFile(null);
          setUploading(false);
          toast({
            title: t("profile_updated_title"),
            description: t("profile_updated_description"),
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      );
    } catch (error) {
      setUploading(false);
      toast({
        title: t("upload_failed_title"),
        description: error.message || t("upload_failed_generic_error"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // State for handling password change
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast({
        title: t("password_mismatch_title"),
        description: t("password_mismatch_description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const user = currentUser;
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      toast({
        title: t("password_changed_title"),
        description: t("password_changed_description"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast({
        title: t("password_change_failed_title"),
        description: error.message || t("password_change_failed_description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      {/* Profile Information */}
      <Card mb={{ base: "0px", lg: "20px" }} align="center">
        <Box bg={`url(${bannerSrc})`} bgSize="cover" borderRadius="16px" h="131px" w="100%" />
        <Image mx="auto" src={imgSrc} h="87px" w="87px" mt="-43px" border="4px solid" borderColor={borderColor} alt={t("profile_picture")} />
        <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
          {name}
        </Text>
      </Card>

      {/* Change Profile Picture */}
      <Card mb={{ base: "0px", lg: "20px" }} align="center">
        <Box mt="4" w="80%" maxW="400px">
          <form onSubmit={handleSubmitProfilePicture}>
            <FormControl isRequired>
              <FormLabel>{t("change_profile_picture")}</FormLabel>
              <Input type="file" accept="image/*" onChange={handleFileChange} />
            </FormControl>
            <Button mt={4} colorScheme="teal" isLoading={uploading} type="submit" w="100%">
              {t("change_profile_picture")}
            </Button>
          </form>
        </Box>
      </Card>

      {/* Change Password */}
      <Card mb={{ base: "0px", lg: "20px" }} align="center">
        <Box mt="4" w="80%" maxW="400px">
          <Text fontSize="xl" mb={4} fontWeight="bold" textAlign="center">
            {t("change_password")}
          </Text>
          <form onSubmit={handlePasswordChange}>
            <FormControl isRequired>
              <FormLabel>{t("old_password")}</FormLabel>
              <InputGroup>
                <Input
                  type={showOldPassword ? "text" : "password"}
                  placeholder={t("enter_old_password")}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <InputRightElement>
                  <Button variant="ghost" onClick={() => setShowOldPassword(!showOldPassword)}>
                    {showOldPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl isRequired mt={4}>
              <FormLabel>{t("new_password")}</FormLabel>
              <InputGroup>
                <Input
                  type={showNewPassword ? "text" : "password"}
                  placeholder={t("enter_new_password")}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <InputRightElement>
                  <Button variant="ghost" onClick={() => setShowNewPassword(!showNewPassword)}>
                    {showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl isRequired mt={4}>
              <FormLabel>{t("confirm_password")}</FormLabel>
              <InputGroup>
                <Input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder={t("confirm_new_password")}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <InputRightElement>
                  <Button variant="ghost" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Button mt={6} colorScheme="teal" type="submit" w="100%">
              {t("change_password")}
            </Button>
          </form>
        </Box>
      </Card>
    </Box>
  );
}
